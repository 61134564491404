import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink, PrimaryButtonInline } from '../../../components';

import css from './NoSearchResultsMaybe.module.css';

const NoSearchResults = props => {
  const { listingsAreLoaded, totalItems, location, resetAll } = props;
  const slug = process.env.REACT_APP_PRE_BOOKING_LISTING_SLUG;
  const id = process.env.REACT_APP_PRE_BOOKING_LISTING_ID;
  const hasNoResult = listingsAreLoaded && totalItems !== 0;
  const hasSearchParams = location.search?.length > 0;
  return hasNoResult ? (
    <div className={css.noSearchResults}>
      <p>
        <PrimaryButtonInline
          className={css.noSearchResultButton}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
        > <NamedLink className={css.createListingLink} name="ListingPagePreBooking" params={{ id, slug, }}>
            <FormattedMessage id="SearchPage.CreateService" />
          </NamedLink></PrimaryButtonInline>
      </p>
    </div >
  ) : null;
};

export default NoSearchResults;
